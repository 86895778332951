@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap');
html {
  background-color: white;
}

* {
  font-family: 'Montserrat', sans-serif;
}
html {
  height: 100%;
}
body {
  height: 100%;
}
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*.react-select-container {
  flex-direction: column;
  border-radius: 1.5rem;
  margin-bottom: 0.5rem;
  width: 18rem;
  max-width: 18rem;
}*/

/*.react-select__control {
  border-radius: 1.5rem !important;
  padding: 0.7rem;
  background-color: transparent !important;
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}*/

/*.react-select__multi-value {
  border-radius: 10px !important;
  color: white;
  background-color: #5a67d8 !important;
}*/

.react-select__multi-value__label {
  color: white !important;
}

.react-select__placeholder {
  margin-left: 35% !important;
}