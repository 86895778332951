#card-button{
    box-shadow: 0px 2px 14px 0px hsla(249, 83%, 60%, 0.486);
    position: relative;
    z-index: 0;
}

#card-button::before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(0.4,0.0,0.2,1);
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: #000000;
    border-radius: 9999px;
    transform: scale(0);
    transition-property: transform,opacity;
}

#card-button:hover::before {
    opacity: 0.08;
    transform: scale(1);
}