#header__profile{
    width:8%;
    text-align: center;
}

#header__nickname{
    width:12.8%;
    text-align: center;
}

#header__city{
    width:12.8%;
    text-align: center;
}

#header__engagement{
    width:12.8%;
    text-align: center;
}

#header__followers{
    width:12.8%;
    text-align: center;
}

#header__tag{
    width:12.8%;
    text-align: center;
}

#header__actions{
    width:28%;
    text-align: center;
}


.pagination{
    display: flex;
    flex-direction: row;
    width: 25%;
    justify-content: space-between;
}