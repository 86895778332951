#main-alert{
    transition: all 0.2s ease-out;
    transform-origin: 100% 100%;
}

.grow-alert{
    transform: translateX(0);
}

.hidden-alert{
    transform: translateX(350px);
}

.alert-success{
    background-color: #EDF7ED;
    color: rgb(30, 70, 32);
}

.alert-warning {
    background-color: #E7F4FD;
    color: rgb(13, 60, 97);
}

.alert-error {
    background-color: #FDECE9;
    color: rgb(97, 26, 21);
}

@media only screen and (min-width: 1024px){
    #main-alert{
        transition: all 0.5s ease-in-out;
        top: unset
    }

    .grow-alert{
        transform: translateY(0);
    }
    
    .hidden-alert{
        transform: translateY(200px);

    }
}