#app-wapper__header{
    transition: all 0.3s ease-in;
}

#menu-opacity-screen{
    transition: all 0.3s ease-in;
    background: rgba(0, 0, 0, 0.15);
}

#burger-icon{
    transition: all 0.3s ease-in;
}