/* This css is for the button hover effect */

.main-button {
    position: relative;
    z-index: 0
}

.main-button:focus{
    outline: none;
}

.main-button::before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(0.4,0.0,0.2,1);
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: #ffffff;
    border-radius: 4px;
    transform: scale(0);
    transition-property: transform,opacity;
}

.main-button:hover::before {
    opacity: 0.08;
    transform: scale(1);
}