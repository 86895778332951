#row__profile{
    width:8%;
}

#row__nickname{
    width:12.8%;
    text-align: center;
}

#row__city{
    width:12.8%;
    text-align: center;
}

#row__engagement{
    width:12.8%;
    text-align: center;
}

#row__followers{
    width:12.8%;
    text-align: center;
}

#row__tag{
    width:12.8%;
    text-align: center;
}

#row__actions{
    width:28%;
    text-align: center;
}
