#category-modal {
    transition: all;
	left: 0; 
	right: 0;
	top: 30%;
	margin-left: auto; 
	margin-right: auto;
	transition: all 0.3s ease;
}

.show-modal{
	transform: translate3d(0px, 0, 0); 
}

.hide-modal {
	transform: translate3d(-1500px, 0, 0); 
}
