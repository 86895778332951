#form__entry{
    transition: all .3s;
}

/* This one is for keeping the form still when selecting an input */
#form__entry:focus-within{
    margin-bottom: -0.5em;
}

.input__label {
    transform: translateY(-2.75em);
    transform-origin:0 0 ;
    transition: all .3s;
    color: #9ba3af;
}

.form__input {
    box-shadow: none;
    transition: all .3s;
    text-align: center;
    color: #393939;
}

.form__input::placeholder{
    color: transparent;
}
.form__input:focus{
    outline: none;
    border: 1px solid #5A67D8;
}

.form__input:focus + .input__label,
.form__input:not(:placeholder-shown) + .input__label {
    transform:translateY(-4.65em);
    font-size: 12px !important;
}